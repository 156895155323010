import { mesDataService } from "@/services/mesDataService";
import { arrange } from "@/libs/common.mutations";

const logLogin = {
    namespaced: true,
    state: {
        data: [],
    },
    getters: {
        
    },
    mutations: {
        SET_DATA(state, payload) {
            arrange(state, payload)
        },       
    },
    actions: {
        async create({ dispatch, commit }, payload) {
            try {                
                const result = await mesDataService.logLogin.create(payload)

                commit('SET_DATA', result.data)
                
                return
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async update({ dispatch, commit }, payload) {
            
            try {
                await mesDataService.logLogin.update(payload.id, payload)
                commit('SET_DATA', payload)              

                return
            } catch (e) {

                dispatch('utils/handleHttpError', e, { root: true })

            }
        },

        
        async listBetweenDate({ dispatch }, payload) {

            try {
                const result = await mesDataService.logLogin.listBetweenDate(payload.startDate, payload.endDate);
                return result.data

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }           
        },

        
        async getLastLoginToday({ dispatch }) {

            try {
                const result = await mesDataService.logLogin.getLastLoginToday();
                
                return result.data

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
         
        },


        async getIp({ dispatch }) {

            try {
                const result = await mesDataService.logLogin.getIp();
                
                return result.data.ip

            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }

        }

    }
}

export { logLogin }
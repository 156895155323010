<template>
  <component :is="component">
    <slot />
  </component>
</template>

<script>



import VuebaseLayoutVue from './layouts/VuebaseLayout.vue';

export default {
  name: 'App',
  components: {
    VuebaseLayoutVue
  },

  data: () => ({

  }),

   
  computed: {
    component() {
      return VuebaseLayoutVue
    }
  }

};
</script>


<!-- <style lang="scss">
@font-face {
  font-family: 'Poppins';
  src: url('@/assets/fonts/poppins.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('@/assets/fonts/montserrat.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Sarabun';
  src: url('@/assets/fonts/sarabun.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

body {
  font-family: 'Poppins', sans-serif;
}

.print-style {
  font-family: 'Montserrat', sans-serif;
}
</style> -->

<style>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");  /* ฟอร์มทางการ สำหรับปริ้น  */
@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  /* font-family: "Montserrat", sans-serif !important; */
  /* font-family: "Sarabun", sans-serif !important; */
}

.v-main {
  background-color: #f5f5f5;
}
</style>

import { mesDataService } from "@/services/mesDataService";

const excavatorPeriodPerformanceDash = {
    namespaced: true,
    state: {
    },

    getters: {
    },

    mutations: {

    },

    actions: {

        async performanceExcavatorPeriodLoadingMeterMonth({ dispatch }, payload) {
            try {
                const result = await mesDataService.dashboard.performanceExcavatorPeriodLoadingMeterMonth(payload.startDate, payload.endDate, payload.projectId, payload.energyId, payload.machineId);

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async performanceExcavatorPeriodEnergyPerMonth({ dispatch }, payload) {
            try {
                const result = await mesDataService.dashboard.performanceExcavatorPeriodEnergyPerMonth(payload.startDate, payload.endDate, payload.projectId, payload.machineId);

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async performanceExcavatorPeriodLoadingVolumeMonth({ dispatch }, payload) {
            try {
                const result = await mesDataService.dashboard.performanceExcavatorPeriodLoadingVolumeMonth(payload.startDate, payload.endDate, payload.projectId, payload.machineId);

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },
        async performanceExcavatorPeriodLoadingMeterYear({ dispatch }, payload) {
            try {
                const result = await mesDataService.dashboard.performanceExcavatorPeriodLoadingMeterYear(payload.year, payload.projectId, payload.energyId, payload.machineId);

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async performanceExcavatorPeriodEnergyPerYear({ dispatch }, payload) {
            try {
                const result = await mesDataService.dashboard.performanceExcavatorPeriodEnergyPerYear(payload.year, payload.projectId, payload.machineId);

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async performanceExcavatorPeriodLoadingVolumeYear({ dispatch }, payload) {
            try {
                const result = await mesDataService.dashboard.performanceExcavatorPeriodLoadingVolumeYear(payload.year, payload.projectId, payload.machineId);

                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        }

    }

}


export { excavatorPeriodPerformanceDash }

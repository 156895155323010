import { mesDataService } from "@/services/mesDataService";

const pmAppointmentAction = {
    namespaced: true,
    state: {
    },
    getters: {
    },
    mutations: {

    },
    actions: {
        async create({ dispatch, rootState }, payload) {

            try {
                payload.updatedBy = rootState.authen.user.email
                const result = await mesDataService.pmAppointmentAction.create(payload)

                dispatch('utils/alertAdded', null, { root: true })

                return result.data

            } catch (e) {

                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async update({ dispatch, rootState }, payload) {
            try {
                payload.updatedBy = rootState.authen.user.email

                const result = await mesDataService.pmAppointmentAction.update(payload)

             //   dispatch('utils/alertUpdated', null, { root: true })

                return result.data
            } catch (e) {
               
                dispatch('utils/handleHttpError', e, { root: true })

            }
        },

       
       
        async searchID({ dispatch}, payload) {
            try {
                const result = await mesDataService.pmAppointmentAction.searchID(payload.id,payload.round,payload.pmActualRoundId);
                               
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async listID({ dispatch}, payload) {
            try {
                const result = await mesDataService.pmAppointmentAction.listID(payload);
                return result.data
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
            }
        },

        async destroy({ dispatch }, id) {
            try {
                await mesDataService.pmAppointmentAction.destroy(id)

                return 1
            } catch (e) {
                dispatch('utils/handleHttpError', e, { root: true })
                return 0
            }
        },
    }
}


export { pmAppointmentAction }


import { mesDataService } from "@/services/mesDataService";

const cmMeter = {
  namespaced: true,
  state: {
    data: [],
    // dataMaxTicket: [],
  },
  getters: {},

  mutations: {
    LIST_DATA(state, payload) {
      payload ? (state.data = payload) : "";
    },

    SET_DATA(state, payload) {
      if (!state.data.some((obj) => obj.id === payload.id)) {
        state.data.splice(0, 0, payload);
      }
    },
    UPDATE_DATA(state, payload) {
      const newData = state.data.map((el) =>
        el.id === payload.id ? Object.assign(el, payload) : el
      );
      state.data = newData;
    },
    DEL_DATA(state, payload) {
      state.data = state.data.filter((obj) => obj.id !== payload);
    },
  },
  actions: {
    async create({ dispatch, commit }, payload) {
      console.log(payload);
      try {
        const result = await mesDataService.cmMeter.create(payload);

        commit("SET_DATA", result.data);
        dispatch("utils/alertAdded", null, { root: true });
        return result.data;
      } catch (e) {
        dispatch("utils/handleHttpError", e, { root: true });
      }
    },
    async list({ dispatch, commit }) {
      try {
        const result = await mesDataService.cmMeter.list();
        // console.log(result);
        commit("LIST_DATA", result.data);
        return result.data;
      } catch (e) {
        dispatch("utils/handleHttpError", e, { root: true });
      }
    },
    async update({ dispatch, commit, rootState }, payload) {
      const uid = rootState.authen.user.email;
  
      payload.updatedBy = uid;
  
      try {
        await mesDataService.cmMeter.update(payload.id, payload);
        commit("SET_DATA", payload);
        dispatch("utils/alertUpdated", null, { root: true });
      } catch (e) {
        dispatch("utils/handleHttpError", e, { root: true });
      }
    },
    async destroy({ dispatch, commit }, id) {
      try {
        await mesDataService.cmMeter.destroy(id);
        commit("DEL_DATA", id);
        return 1;
      } catch (e) {
        dispatch("utils/handleHttpError", e, { root: true });
        return 0;
      }
    },
  },

};

export { cmMeter };

import { auth } from "@/firebase";
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "dashboard",
    component: () => import("../components/production/DashboardView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/setting",
    name: "setting",
    component: () => import("../components/production/SettingView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/production",
    name: "production",
    component: () => import("../views/ProductionView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/report",
    name: "report",
    component: () => import("../components/production/ReportView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/register",
    name: "register",
    component: () => import("../views/RegisterView.vue"),
  },
  {
    path: "/maintenance",
    name: "maintenance",
    component: () => import("../views/MaintenanceView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/pmTableMachine",
    name: "pmTableMachine",
    component: () =>
      import("../components/maintenance/components/pmTableMachine.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/pmMaterialRequestApproval",
    name: "pmMaterialRequestApproval",
    component: () =>
      import("../components/maintenance/components/pmMrApproval.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/pmReport",
    name: "pmReport",
    component: () => import("../views/MaintenanceView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/pmPrintPm",
    name: "PrintPm",
    component: () =>
      import(
        "../components/maintenance/components/pmActionPlan/pmCheckPrint.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/maintenance/pmPrintMr",
    name: "PrintMr",
    component: () =>
      import(
        "../components/maintenance/components/reports/template/MrPrint.vue"
      ),
    meta: { requiresAuth: true },
  },
  {
    path: "/report/ITD_OILUSE",
    name: "PrintITD_OilUse",
    component: () =>
      import("../components/production/reports/components/ITD/ITDprint.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/maintenance/correctiveMaintenance",
    name: "correctiveMaintenance",
    component: () => import("../views/CMaintenanceView.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/cmPrintCm",
    name: "PrintCm",
    component: () => import("../components/maintenance/correctiveMaintenance/components/cmPrint.vue"),
    meta: { requiresAuth: true },
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach((to, from, next) => {
//   auth.onAuthStateChanged(user => {
//       const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

//     if (requiresAuth && !user) {
//       next('/login');
//     } else if (!requiresAuth && user) {
//       next('/');
//     } else {
//       next();
//     }
//   })
// });

// ใช้ router.beforeEach เพื่อจัดการการตรวจสอบ token และการเปลี่ยนหน้า
router.beforeEach(async (to, from, next) => {
  // ตรวจสอบว่าหน้าต้องการการล็อกอินหรือไม่
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  // รอให้ Firebase ตรวจสอบผู้ใช้ปัจจุบัน
  auth.onAuthStateChanged(async (user) => {
    if (user) {
      try {
        await user.getIdToken(true); // true เพื่อบังคับ refresh

        if (!requiresAuth) {
          next("/");
        } else {
          next();
        }
      } catch (error) {
        console.error("Error refreshing token:", error);
        next("/login"); // ในกรณีที่เกิดปัญหา เช่น refreshToken หมดอายุ
      }
    } else {
      if (requiresAuth) {
        next("/login");
      } else {
        next();
      }
    }
  });
});

export default router;
